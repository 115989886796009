import styled from "styled-components";
import { SpanStyled } from "./Elements";
import { Spinner } from "react-bootstrap";
import { ArrowDown } from "./AllImages";

export const sliceProps = (props, sub) => {
  const { children, ...otherProps } = props;
  sub.forEach((prop) => delete otherProps[prop]);
  return otherProps;
};

export const ButtonCustom = (props) => {
  const unwantedProps = ["icon", "label", "clName"];
  const buttonProps = props.type ? { href: props.type } : {};
  return (
    <ButtonWrapper
      {...sliceProps(props, unwantedProps)}
      className={`d-flex align-items-center ${props.clName || ""}`}
    >
      <button
        {...buttonProps} // Conditionally spread the href prop if it exists
        className={`d-flex align-items-center ${props.className || ""} ${
          !props.icon ? "Btn-icon" : ""
        } `}
        {...buttonProps}
        autoComplete="false"
        onClick={() => {
          if (props.type) {
            window.location.href = props.type;
          }
        }}
      >
        {props.icon && <props.icon />}
        <SpanStyled size="16px">{props.label ? props.label : ""}</SpanStyled>
      </button>
    </ButtonWrapper>
  );
};

export const ContactButton = (props) => {
  const unwantedProps = [
    "icon",
    "label",
    "className",
    "clName",
    "onClick",
    "isLoading",
  ];
  const buttonProps = props.type ? { href: props.type } : {};
  return (
    <ContactWrapper
      {...sliceProps(props, unwantedProps)}
      className={`contactWrapper  ${props.clName ? props.clName : ""}`}
      disabled={props.disabled}
    >
      <button
        className={`d-flex align-items-center ${props.className || ""} ${
          !props.icon ? "Btn-icon" : ""
        } `}
        {...buttonProps}
        onClick={props.onClick}
        autoComplete="false"
        disabled={props.disabled}
      >
        {props.isLoading ? (
          <Spinner variant="success" />
        ) : (
          <>
            <SpanStyled size={props.size ? props.size : "18px"}>
              {props.label ? props.label : ""}
            </SpanStyled>
            {props.icon && <props.icon width="22px" />}
          </>
        )}
      </button>
    </ContactWrapper>
  );
};

// Dropdown component
export const SelectionDropdown = (props) => {
  const unwantedProps = ["options"];
  return (
    <DropdownStyled {...sliceProps(props, unwantedProps)} arrow={ArrowDown}>
      <option value="">
        {props.placeholder && props.placeholder}
      </option>
      {props.options && props.options.length > 0 && props.options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </DropdownStyled>
  );
};

export const ButtonWrapper = styled.div`
  button,
  button::after {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }

  button {
    border: none;
    border-radius: 0px;
    display: flex;
    gap: 8px;
    height: 48px;
    padding: 0px 14px;
    border-radius: 6px;
    position: relative;
    background: ${(props) => (props.bg ? props.bg : "var(--themeColor)")};
    border: 1px solid
      ${(props) => (props.border ? `${props.border}` : "transparent")};
    color: ${(props) =>
      props.color ? `${props.color}` : "var(--themeColor2)"};
    /* color: var(--white); */
    span {
      text-wrap: nowrap;
    }
    /* & svg {
      [fill] {
        fill: var(--white);
      }
    } */
  }

  /* button:hover {
    background: var(--hoverBg);
  } */
`;

export const ContactWrapper = styled.div`
  display: flex;
  /* padding-top: 20px; */
  width: fit-content;
  button,
  button::after {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
  button {
    background: ${(props) => (props.bg ? props.bg : "var(--themeColor)")};
    border: 1px solid
      ${(props) => (props.border ? `var(${props.border})` : "transparent")};
    display: flex;
    gap: 12px;
    height: 44px;
    padding: 0px 20px;
    position: relative;
    border-radius: 6px;
    box-shadow: 0px 4px 8px 0px #064a9340;
    span {
      text-wrap: nowrap;
      /* font-family: var(--regular); */
      color: ${(props) => (props.color ? props.color : "var(--white)")};
    }
    & svg {
      height: 22px;
      [fill] {
        fill: var(--darkColor);
      }
    }
    /* &:hover {
      background: var(--hoverBg);
    } */
    ${(props) =>
      props.disabled &&
      `
        background: var(--darkColorHover);
        color: var(--textSecondary);
        cursor: not-allowed;
        border-bottom-color: var(--disabledBorder);
        
        span {
          color: var(--textSecondary);
        }

        & svg [fill] {
          fill: var(--textSecondary);
        }

        &:hover {
          background: var(--darkColorHover);
        }
      `}
  }
`;

export const InputStyled = styled.input`
  background: var(--lightBg);
  border: ${({border}) => border ? `1px solid ${border}` : 'none'};
  border-radius: 8px;
  height: 43px;
  width: 100%;
  color: var(--white);
  caret-color: var(--white);
  -webkit-text-fill-color: var(--white) !important;
  &:not(.no-icon) {
    padding-left: 20px;
  }
  &.no-icon {
    padding-left: 18px;
  }
  padding-right: 18px;
  outline: none;
  /* &,
  &::placeholder {
    font-size: 14px;
    color: var(--textPalceholder);
  } */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    /* -webkit-box-shadow: 0 0 0 30px var(--lightBg) inset !important; */
    background: var(--lightBg) !important;
    -webkit-text-fill-color: var(--white) !important;
    transition: background-color 5000s ease-in-out 0s;
    color: var(--white);
  }
`;
export const SecondaryInputStyled = styled.input`
  /* background: var(--lightBg); */
  border: ${({border}) => border ? `1px solid ${border}` : 'none'};
  border-radius: 8px;
  height: 43px;
  width: 100%;
  /* color: var(--white);
  caret-color: var(--white);
  -webkit-text-fill-color: var(--white) !important; */
  &:not(.no-icon) {
    padding-left: 20px;
  }
  &.no-icon {
    padding-left: 18px;
  }
  padding-right: 18px;
  outline: none;
  &,
  &::placeholder {
    color: var(--text);
  }
`;
export const TextAreaStyled = styled.textarea`
  background: var(--lightBg);
  border: none;
  border-radius: 8px;
  height: 122px;
  width: 100%;
  padding-top: 10px;
  color: var(--white);
  caret-color: var(--white);
  -webkit-text-fill-color: var(--white) !important;
  &:not(.no-icon) {
    padding-left: 20px;
  }
  &.no-icon {
    padding-left: 18px;
  }
  padding-right: 18px;
  outline: none;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    /* -webkit-box-shadow: 0 0 0 30px var(--lightBg) inset !important; */
    background: var(--lightBg) !important;
    -webkit-text-fill-color: var(--white) !important;
    transition: background-color 5000s ease-in-out 0s;
    color: var(--white);
  }
`;
export const SecondaryTextAreaStyled = styled.textarea`
  background: var(--lightBg);
  border: ${({border}) => border ? `1px solid ${border}` : 'none'};
  border-radius: 8px;
  height: 122px;
  width: 100%;
  padding-top: 10px;
  &:not(.no-icon) {
    padding-left: 20px;
  }
  &.no-icon {
    padding-left: 18px;
  }
  padding-right: 18px;
  outline: none;
  &,
  &::placeholder {
    color: var(--text);
  }
`;

const DropdownStyled = styled.select`
  border: ${({ border }) => (border ? `1px solid ${border}` : 'none')};
  border-radius: 8px;
  height: 43px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  outline: none;
  background: var(--lightBg);
  color: var(--text);
  /* appearance: none;

  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-image: url(${(props) => props.arrow});
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
  } */

  &::placeholder {
    color: var(--textPlaceholder);
  }

  &:hover {
    background: var(--hoverBg);
  }
  option[disabled] {
    color: var(--textPlaceholder);
  }
`;
