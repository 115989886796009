import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.scss";
import "./assets/css/responsive.scss";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n, { i18nInitialized } from "./Languages/INEXT";

const renderApp = () => {
  const rootEl = document.getElementById("root");
  const root = ReactDOM.createRoot(rootEl);
  root.render(
    <I18nextProvider i18n={i18n}>
      <Router>
        <App />
      </Router>
    </I18nextProvider>
  );
};
i18nInitialized.then(renderApp);
