import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import { Globe } from "../components/Styled/AllImages";
import { useTranslation } from "react-i18next";

const LangChangeButton = () => {
  const { i18n } = useTranslation();
  const [isEnglishSelected, setIsEnglishSelected] = useState(
    Cookies.get("lang") === "en" ? true : false
  );

  const changeLanguageAndDirection = (lng, dir) => {
      i18n.changeLanguage(lng);
      document.documentElement.dir = dir;
      document.body.classList.toggle("rtl", dir === "rtl");
      Cookies.set("lang", lng, { expires: 60, path: "/" });
      Cookies.set("dir", dir, { expires: 60, path: "/" });
  };

  const handleLanguageChange = () => {
    const newLang = isEnglishSelected ? "ar" : "en";
    const newDir = isEnglishSelected ? "rtl" : "ltr";
    changeLanguageAndDirection(newLang, newDir);
    setIsEnglishSelected(!isEnglishSelected);
  };

  useEffect(() => {
    const savedLang = Cookies.get("lang") || "ar";
    const savedDir = Cookies.get("dir") || "rtl";

    changeLanguageAndDirection(savedLang, savedDir);
  }, []);

  return (
    <ButtonBox onClick={handleLanguageChange}>
      <span className="change-Btn">
        <Globe className="icon" alt={isEnglishSelected ? "Arabic" : "USA"} />
        <span className="lang-text">{isEnglishSelected ? "العربية" : "English"}</span>
      </span>
    </ButtonBox>
  );
};

const ButtonBox = styled.div`
  padding: 0;
  cursor: pointer;
  z-index: 1;

  .change-Btn {
    background: var(--themeColor2);
    border: none;
    border-radius: 6px;
    color: var(--white);
    display: flex;
    align-items: center;
    font-size: 16px;
    height: 44px;
    padding: 0px 20px;
    position: relative;
    text-transform: uppercase;

    svg {
      width: 22px;
      height: auto;
      margin: 0 6px;
    }

    span {
      padding-bottom: 0;
      font-family: var(--arBoldSans);
    }
  }
  .icon {
    cursor: pointer;
    z-index: 1;
  }

  @media (max-width: 1199px) {
    .lang-text {
      display: none
    } 
  }
  @media (max-width: 470px) {
    .change-Btn {
      padding: 0 10px!important;
    }
  }
`;

export default LangChangeButton;
