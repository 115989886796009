import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import styled from "styled-components";
import { Box, LazyImage } from "../../../Styled/Elements";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const FeatureSlider = ({ slidesList }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [selectedModule, setSelectedModule] = useState({});
  const [sliderKey, setSliderKey] = useState(0);
  const swiperRef = useRef(null);

  const handleSlideChange = (swiper) => {
    const realIndex = swiper.realIndex;
    setSelectedModule(slidesList[realIndex]);
  };

  useEffect(() => {
    setSelectedModule(slidesList[0]);
    setSliderKey((prevKey) => prevKey + 1);
  }, [language]);

  return (
    <SliderStyled>
      <Box className={`slider-container`}>
        {slidesList.length > 0 && (
          <Swiper
            key={sliderKey}
            modules={[Navigation]}
            slidesPerView={3}
            centeredSlides={true}
            // slideToClickedSlide={true}
            loop={true}
            dir={language == "ar" ? "rtl" : "ltr"}
            breakpoints={{
              640: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
            }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            onSlideChange={handleSlideChange}
          >
            {slidesList.map((module, index) => (
              <SwiperSlide
                key={index}
                className="module-icon-container"
                onClick={() => {
                  if (swiperRef.current) {
                    swiperRef.current.slideToLoop(index);
                  }
                }}
              >
                <Row className="justify-content-center">
                  <Box className="module-icon">
                    <Box>{module.icon}</Box>
                    <Box className="slide-name text-center">
                      {t(module.name)}
                    </Box>
                  </Box>
                </Row>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Box>

      <Box>
        <Box className="module-preview">
          <LazyImage
            src={selectedModule.previewImage}
            alt={selectedModule.name}
            className="preview-image"
          />
        </Box>
      </Box>
    </SliderStyled>
  );
};

export default FeatureSlider;
const SliderStyled = styled.div`
  width: 100%;

  .module-icon-container {
    width: 100px;
    padding: 10px;
    cursor: pointer;
  }
  .module-icon-container svg {
    [stroke] {
      stroke: var(--iconColor);
    }
    [fill] {
      fill: var(--iconColor);
    }
  }
  .module-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0;
    max-width: 100px;
    height: 100px;
    border-radius: 10px;
  }
  .slide-name {
    color: var(--iconColor);
  }
  .swiper-slide-active {
    &.module-icon-container svg {
      [stroke] {
        stroke: var(--white);
      }
      [fill] {
        fill: var(--white);
      }
    }
    .module-icon {
      background: var(--themeGradient);
    }
    .slide-name {
      color: var(--white);
    }
  }

  .module-preview {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }

  .module-preview img {
    width: 100%;
    max-width: 700px;
    height: auto;
    object-fit: cover;
  }

  .preview-image {
    width: 100%;
  }
`;
